window.crossJson = function crossJson(target, type) {
  let url = "/data/" + encodeURIComponent(target);

  $.ajax({
    type: 'GET',
    url: url,
    dataType: "json",
    success: function (data) {
      setData(data, type);
    },
    error: function (jqXHR, textStatus, errorThrown) {
      console.warn("fail getting data");
    }
  });
}

window.setData = function setData(data, type) {
  switch (type) {
    case "spec":
      setDataSpec(data.Result.Obj[0].Items);
      break;

    case "gallery":
      setDataGallery(data.Result.Obj);
      break;

    default:
      break;
  }
}

window.setDataSpec = function setDataSpec(data) {
  // console.log(data);
  let tbl_body = "";
  $.each(data, function () {
    tbl_body += "<tr><th scope='row'>" + this.Name + "</th><td>" + this.Spec + "</td></tr>";
  });
  $("#table--sp tbody").html(tbl_body);
}

window.setDataGallery = function setDataGallery(data) {
  // console.log(data);
  let content = "";
  $.each(data, function () {
    content += '<div class="col"><a data-fancybox="gallery" class="gallery_unit" href="' + this.ImgUrl + '"><img src="' + this.ImgUrl + '"></a></div>';
  });
  $("#gallery_row").html(content);
}